<template>
  <b-modal
    v-model="modal"
    :title="title"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    body-class="max-h-375px project-role-management-modal"
  >
    <!-- begin:: Modal Body -->
    <!-- begin:: Form input -->
    <b-form-group class="mb-0">
      <div class="mb-6">
        <AppFormField
          v-model="$v.skillObj.name.$model"
          :state="validateObjState('skillObj', 'name')"
          :validationValue="$v.skillObj.name"
          :title="$t('label.name')"
          :validators="['required']"
          :disabled="isFormDisabled"
          solid
        ></AppFormField>
      </div>
    </b-form-group>
    <!-- end:: Form Input -->

    <!-- begin:: Is Deleted Field -->
    <div
      v-if="!isNewRecord"
      class="project-role-management-modal__is-deleted-field-container"
    >
      <label class="font-size-sm">
        {{ $t('label.isDeleted') }}
      </label>
      <AppCheckCircle v-model="skillObj.isDeleted"></AppCheckCircle>
    </div>
    <!-- end:: Is Deleted Field -->

    <!-- end:: Modal Body -->

    <!-- begin:: Modal Footer -->
    <template #modal-footer>
      <b-button
        v-if="isShowDiscardButton"
        class="mr-2"
        variant="white"
        @click="onClickDiscard"
        >{{ $t('label.discard') }}</b-button
      >
      <b-button
        v-if="isShowSaveChangesButton"
        variant="primary"
        @click="onClickSaveChanges"
        >{{ $t('label.saveChanges') }}</b-button
      >
      <b-button
        v-if="isShowSubmitButton"
        variant="success"
        @click="onClickSubmit"
        >{{ $t('label.submit') }}</b-button
      >
      <b-button
        v-if="isShowCloseButton"
        variant="primary"
        @click="onClickClose"
        >{{ $t('label.close') }}</b-button
      >
    </template>
    <!-- end:: Modal Footer -->
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    SKILLS_GET_SKILL,
    SKILLS_CREATE_SKILL,
    SKILLS_UPDATE_SKILL,
    SKILLS_INITIAL_SKILL_STATE,
    SKILLS_INITIAL_CREATE_SKILL_STATE,
    SKILLS_INITIAL_UPDATE_SKILL_STATE
  } from '@/core/store/skills.module';

  export default {
    name: 'SkillManagementModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField,
      AppCheckCircle
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      isNewRecord: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      modal: false,
      currentModule: MODULE.SKILL,
      skillObj: {
        name: null,
        isDeleted: false
      },
      formFields: [
        {
          model: 'name',
          title: 'name',
          validators: ['required']
        },
      ],
    }),
    computed: {
      skillComplete() {
        return this.$store.state.skills.skill.complete;
      },
      createSkillComplete() {
        return this.$store.state.skills.createSkill.complete;
      },
      updateSkillComplete() {
        return this.$store.state.skills.updateSkill.complete;
      },
      isFormDisabled() {
        var moduleName = this.currentModule;
        var hasAccessRight = (module, actions) => {
          return this.checkAccessRight(module, actions);
        };

        return this.isNewRecord
          ? !hasAccessRight(moduleName, [ACTION.SKILL_CREATE])
          : !hasAccessRight(moduleName, [ACTION.SKILL_UPDATE]);
      },
      isShowSubmitButton() {
        return (
          this.isNewRecord &&
          this.checkAccessRight(this.currentModule, [
            ACTION.SKILL_CREATE
          ])
        );
      },
      isShowDiscardButton() {
        return this.isNewRecord
          ? true
          : this.checkAccessRight(this.currentModule, [
              ACTION.SKILL_UPDATE
            ]);
      },
      isShowCloseButton() {
        return this.isNewRecord
          ? false
          : !this.checkAccessRight(this.currentModule, [
              ACTION.SKILL_UPDATE
            ]);
      },
      title() {
        return this.isNewRecord
          ? i18nHelper.getMessage('label.newSkill')
          : i18nHelper.getMessage('label.skillDetails');
      },
      isShowSaveChangesButton() {
        return (
          !this.isNewRecord &&
          this.checkAccessRight(this.currentModule, [
            ACTION.SKILL_UPDATE
          ])
        );
      }
    },
    watch: {
      value() {
        if (this.value) {
          if (this.isNewRecord) {
            this.modal = true;
          } else {
            this.getSkill(this.id);
          }
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      skillComplete() {
        let response = this.$store.state.skills.skill;
        let title = i18nHelper.getMessage('label.getSkill');
        let initialStateAction = SKILLS_INITIAL_SKILL_STATE;
        let successAction = (response) => {
          this.getSkillCompleteActions(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createSkillComplete() {
        var response = this.$store.state.skills.createSkill;
        var title = i18nHelper.getMessage('label.createNewSkill');
        var initialStateAction = SKILLS_INITIAL_CREATE_SKILL_STATE;
        var successAction = () => {
          this.closeModalAction();
          this.initialList();
        };

        if (response.complete) {
          this.actionCreateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateSkillComplete() {
        var response = this.$store.state.skills.updateSkill;
        var title = i18nHelper.getMessage('label.updateSkill');
        var initialStateAction = SKILLS_INITIAL_UPDATE_SKILL_STATE;
        var successAction = () => {
          this.closeModalAction();
          this.initialList();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
    },
    methods: {
      onClickSaveChanges() {
        var data = {
          ...this.skillObj,
        };
        var id = this.id;

        if (this.validationCheck('skillObj')) {
          this.updateSkill(id, data);
        }
      },
      onClickDiscard() {
        this.closeModalAction();
      },
      onClickSubmit() {
        var data = {
          ...this.skillObj,
        };

        if (this.validationCheck('skillObj')) {
          this.createSkill(data);
        }
      },
      initialList() {
        this.$parent.$refs.appSmartList.initAppSmartList();
      },
      getSkillCompleteActions(response) {
        this.skillObj = response.data;
        this.modal = true;
        this.$v.$reset();
      },
      getProjectTypesCompleteActions(response) {
        this.projectTypesOptions = response.data;
      },
      onClickClose() {
        this.closeModalAction();
      },
      resetForm() {
        this.skillObj = {
          name: null,
          isDeleted: false
        };

        this.$v.$reset();
      },
      closeModalAction() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      updateSkill(id, data) {
        this.$store.dispatch(SKILLS_UPDATE_SKILL, { id, data });
      },
      createSkill(data) {
        this.$store.dispatch(SKILLS_CREATE_SKILL, { data });
      },
      getSkill(id) {
        this.$store.dispatch(SKILLS_GET_SKILL, {
          id
        });
      },
    },
    validations: {
      skillObj: {
        name: {
          required
        },
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
