<template>
  <div>
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
      @app-smart-list-action-new-record="newRecordAction"
      @app-smart-list-open-modal-details="openModalDetailsAction"
    ></AppSmartList>

    <!--begin:: App Update Details Modal-->
    <SkillManagementModal
      v-model="skillDetailsModal.isShow"
      :id="skillDetailsModal.id"
      :isNewRecord="skillDetailsModal.isNewRecord"
    ></SkillManagementModal>
    <!--end:: App Update Details Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList';
  import SkillManagementModal from '@/views/components/talent-category/skill/SkillManagementModal';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    SKILLS_GET_SKILLS,
    SKILLS_DELETE_SKILL,
    SKILLS_INITIAL_SKILLS_STATE,
    SKILLS_INITIAL_DELETE_SKILL_STATE
  } from '@/core/store/skills.module';

  export default {
    name: 'SkillList',
    components: {
      AppSmartList,
      SkillManagementModal
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.talentCategory'),
            route: { name: ROUTE_NAME.TALENT_CATEGORY }
          },
          {
            title: i18nHelper.getMessage('label.skill'),
            route: { name: ROUTE_NAME.TALENT_CATEGORY_SKILL }
          },
          { title: i18nHelper.getMessage('label.skillList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.SKILL,
        actions: {
          create: ACTION.SKILL_CREATE,
          read: ACTION.SKILL_READ,
          update: ACTION.SKILL_UPDATE,
          delete: ACTION.SKILL_DELETE
        }
      },
      filters: [
        {
          label: 'name',
          key: 'name',
          value: ''
        }
      ],
      actions: {
        viewList: {
          module: 'skills',
          state: 'skills',
          action: SKILLS_GET_SKILLS,
          initialStateAction: SKILLS_INITIAL_SKILLS_STATE,
          title: i18nHelper.getMessage('label.getSkills')
        },
        deleteRecord: {
          module: 'skills',
          state: 'deleteSkill',
          action: SKILLS_DELETE_SKILL,
          initialAction: SKILLS_INITIAL_DELETE_SKILL_STATE,
          title: i18nHelper.getMessage('label.deleteSkill'),
          message: i18nHelper.getMessage('message.requestDeleteMessage', [
            i18nHelper.getMessage('label.skill').toLowerCase()
          ]),
          successAction: () => {}
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true
        },
        {
          key: 'isDeleted',
          name: 'isDeleted',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true,
            hasActionDelete: true
          }
        }
      ],
      skillDetailsModal: {
        isShow: false,
        id: 0,
        isNewRecord: false
      },
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newSkill'),
        isModal: true
      }
    }),
    created() {
      this.initSkillList();
    },
    methods: {
      importSuccessAction() {
        this.actions.deleteRecord.successAction = () => {
          this.$refs.appSmartList.initAppSmartList();
        };
      },
      newRecordAction() {
        this.openSkillManagementModal(true);
      },
      openModalDetailsAction(value) {
        this.openSkillManagementModal(false, value);
      },
      openSkillManagementModal(isNewRecord, id) {
        this.skillDetailsModal = {
          isShow: true,
          id: id,
          isNewRecord: isNewRecord
        };
      },
      initSkillList() {
        this.importSuccessAction();
      }
    }
  };
</script>

<style></style>
